import authenticate from "@/auth/authenticate";

export default [
  {
    path: "/app",
    component: () =>
      import(/* webpackChunkName: "app" */ "@/views/global/Index"),
    beforeEnter: authenticate,

    children: [
      {
        path: "",
        name: "app.index",
        redirect: "/app/ticket",
      },
      {
        path: "ticket",
        name: "app.ticket",
        component: () =>
          import(/* webpackChunkName: "app" */ "@/views/app/Index"),
        meta: { layout: "HorizontalBar" },
      },
 
      {
        path: "ticket/:id",
        name: "app.ticket.details",
        component: () =>
          import(/* webpackChunkName: "app" */ "@/views/app/ticket/TicketView"),
        meta: { layout: "HorizontalBar" },
      },
      {
        path: "payment/:id",
        name: "app.payment.details",
        component: () =>
          import(/* webpackChunkName: "app" */ "@/views/app/ticket/PaymentView"),
        meta: { layout: "HorizontalBar" },
      },
      {
        path: "membership",
        name: "app.membership",
        component: () =>
          import(
            /* webpackChunkName: "app" */ "@/views/app/membership/MyMemberships"
          ),
        meta: { layout: "HorizontalBar" },
      },
      {
        path: "membership/:id",
        name: "app.membership.details",
        component: () =>
          import(
            /* webpackChunkName: "app" */ "@/views/app/membership/FiliationDetails"
          ),
        meta: { layout: "HorizontalBar" },
      },
      {
        path: "*",
        redirect: "/app",
      },
    ],
  },
];
