import Vue from "vue";
import moment from "moment";

moment.locale("pt-br");

Vue.filter("percentage", function (value, decimals = 2) {
  if (!value) return "0%";
  value = parseFloat(value || 0);
  return `${(value * 100).toFixed(decimals)}%`;
});

Vue.filter("currency", function (value, hiddenDecimals = false) {
  value = parseFloat(value || 0);
  const fValue = value.toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2,
  });
  if (hiddenDecimals) return fValue.replace(",00", "");

  return fValue;
});

Vue.filter("membershipDuration", function (value) {
  if (value.validationType === "PERIOD") {
    const duration = value.duration;
    const durationType = value.durationType;
    let result = "";
    const MONTHS = {
      1: "mensal",
      2: "bimestral",
      3: "trimestral",
      6: "semestral",
      12: "anual",
      24: "bienal",
    };

    switch (durationType) {
      case "YEAR":
        result = MONTHS[duration * 12] || `${duration} anos`;
        break;

      case "MONTH":
        result = MONTHS[duration] || `${duration} meses`;
        break;

      case "DAY":
        result = `${duration} dia${duration > 1 ? "s" : ""}`;
        break;
    }

    return result;
  } else if (value.validationType === "DATE") {
    return `até ${moment(value.endDate).format("DD/MM/YYYY")}`;
  }
});

Vue.filter("firstName", function (value) {
  if (!value) return "";
  value = value.toString();
  return value.split(" ")[0];
});

Vue.filter("shortName", function (value) {
  if (!value) return "";
  value = value.toString();
  const names = value.split(" ");
  const shortNames = names.map((name, index) => {
    if (index === 0) return name;
    if (name.length <= 2) return name;
    return name.charAt(0).toUpperCase() + ".";
  });
  return shortNames.join(" ");
});


Vue.filter("date", function (value, format = "DD/MM/YYYY") {
  if (!value) return "";
  return moment(value).format(format);
});

Vue.filter(
  "startEndDate",
  function (
    value,
    startKey = "start",
    endKey = "end",
    dateHourSeparator = " - ",
    { month: monthFormat = "/M", day: dayFormat = "DD" } = {}
  ) {
    var response = "";
    response += value[startKey]
      ? moment(value[startKey]).format(
          `${dayFormat}${monthFormat}[${dateHourSeparator}]HH:mm`
        )
      : "";
    if (value[endKey]) {
      const isSameDay = moment(value[startKey]).isSame(value[endKey], "day");
      response += ` até ${moment(value[endKey]).format(
        isSameDay
          ? "HH:mm"
          : `${dayFormat}${monthFormat}[${dateHourSeparator}]HH:mm`
      )}`;
    }
    return response;
  }
);

Vue.filter("dateDiff", function ([a, b], ...args) {
  const date1 = moment(a);
  const date2 = moment(b);

  const duration = moment.duration(date1.diff(date2));
  return duration.humanize(...args);
});

Vue.filter("status", function (value, returnType = "text") {
  if (!value) return "";
  const statusList = {
    succeeded: {
      text: "Aprovado",
      color: "success",
    },
    canceled: {
      text: "Cancelado",
      color: "info",
    },
    refunded: {
      text: "Reembolsado",
      color: "info",
    },
    disputed: {
      text: "Disputa",
      color: "error",
    },
    rejected: {
      text: "Rejeitado",
      color: "error",
    },
    pending: {
      text: "Pendente",
      color: "warning",
    },
    requires_payment_method: {
      text: "Aguardando Pagamento",
      color: "warning",
    },
    requires_confirmation: {
      text: "Aguardando Confirmação",
      color: "warning",
    },
    requires_action: {
      text: "Aguardando Ação",
      color: "warning",
    },
    processing: {
      text: "Processando",
      color: "warning",
    },
    requires_capture: {
      text: "Aprovado (Não Capturado)",
      color: "success",
    },
  };
  return statusList[value][returnType] || value;
});

Vue.filter("shortId", function (uuid) {
  if (!uuid) return "";
  return uuid.split("-")[0].toUpperCase();
});
